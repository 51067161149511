.root {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;

}

html {
  color: rgba(22, 22, 22, 0.911);
}

body {
  background-color:  #FFB6C1;
}
.main-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  width: 90%;
}

.main-button {
  width: fit-content;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.432);
  border-radius: 5px;
  color: black;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: #d6401e91;
    color: white;
  }
}